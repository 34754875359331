import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  createStylesParam,
} from '@wix/tpa-settings';

export type IStylesParams = {
  backgroundColor: StyleParamType.Color;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
  topAndBottomPadding: StyleParamType.Number;
  sidePadding: StyleParamType.Number;
  formFieldsGap: StyleParamType.Number;
  widgetBackgroundColor: StyleParamType.Color;
  selectionColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  dividerColorAndOpacity: StyleParamType.Color;
  widgetBorderWidth: StyleParamType.Number;
  widgetBorderColorAndOpacity: StyleParamType.Color;
  widgetBorderRadius: StyleParamType.Number;
  formBorderWidth: StyleParamType.Number;
  formBorderColor: StyleParamType.Color;
  formBorderRadius: StyleParamType.Number;
  formFieldBackgroundColor: StyleParamType.Color;
  sectionTitleColor: StyleParamType.Color;
  sectionTitleFont: StyleParamType.Font;
  primaryTextColor: StyleParamType.Color;
  primaryTextFont: StyleParamType.Font;
  secondaryTextColor: StyleParamType.Color;
  secondaryTextFont: StyleParamType.Font;
  formFieldsLabelsColor: StyleParamType.Color;
  formFieldsLabelsFont: StyleParamType.Font;
  formFieldsInputTextColor: StyleParamType.Color;
  formFieldsInputTextFont: StyleParamType.Font;
  mobileServiceNameFontSize: StyleParamType.Number;
  mobileServiceTitlesFontSize: StyleParamType.Number;
  mobilePrimaryTextFontSize: StyleParamType.Number;
  mobileSecondaryTextFontSize: StyleParamType.Number;
  mobileFormFieldsLabelsFontSize: StyleParamType.Number;
  mobileFormFieldsInputsFontSize: StyleParamType.Number;
  mobileButtonSize: StyleParamType.Number;
  mainButtonTextColor: StyleParamType.Color;
  mainButtonFont: StyleParamType.Font;
  mainButtonBackgroundColorAndOpacity: StyleParamType.Color;
  mainButtonBorderColorAndOpacity: StyleParamType.Color;
  mainButtonBorderWidth: StyleParamType.Number;
  mainButtonBorderRadius: StyleParamType.Number;
  mainButtonHoverBackgroundColorAndOpacity: StyleParamType.Color;
  mainButtonHoverColorAndOpacity: StyleParamType.Color;
  mainButtonHoverTextColor: StyleParamType.Color;
  secondaryButtonTextColor: StyleParamType.Color;
  secondaryButtonFont: StyleParamType.Font;
  secondaryButtonBackgroundColorAndOpacity: StyleParamType.Color;
  secondaryButtonBorderColorAndOpacity: StyleParamType.Color;
  secondaryButtonBorderWidth: StyleParamType.Number;
  secondaryButtonBorderRadius: StyleParamType.Number;
  secondaryButtonHoverBackgroundColorAndOpacity: StyleParamType.Color;
  secondaryButtonHoverColorAndOpacity: StyleParamType.Color;
  secondaryButtonHoverTextColor: StyleParamType.Color;
};

const widgetBackgroundColor = createStylesParam('widgetBackgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1'),
});

const mainButtonBorderWidth = createStylesParam('mainButtonBorderWidth', {
  type: StyleParamType.Number,
  getDefaultValue: () => 1,
});

const mainButtonBorderRadius = createStylesParam('mainButtonBorderRadius', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  topAndBottomPadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 48,
  },
  sidePadding: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 20,
  },
  formFieldsGap: {
    type: StyleParamType.Number,
    inheritDesktop: false,
    getDefaultValue: () => 24,
  },
  widgetBackgroundColor,
  selectionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dividerColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  widgetBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  widgetBorderColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  widgetBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  formBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  formBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  formFieldBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(widgetBackgroundColor) ?? wixColorParam('color-1'),
  },
  sectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 20 }),
  },
  sectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  primaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  primaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  secondaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  secondaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  formFieldsLabelsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formFieldsLabelsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  formFieldsInputTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  formFieldsInputTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mobileServiceNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },
  mobileServiceTitlesFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  mobilePrimaryTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileSecondaryTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileFormFieldsLabelsFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileFormFieldsInputsFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileButtonSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mainButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  mainButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  mainButtonBackgroundColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonBorderColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  mainButtonBorderWidth,
  mainButtonBorderRadius,
  mainButtonHoverBackgroundColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  mainButtonHoverColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  mainButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  secondaryButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  secondaryButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  secondaryButtonBackgroundColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  secondaryButtonBorderColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  secondaryButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(mainButtonBorderWidth) ?? 1,
  },
  secondaryButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) =>
      getStyleParamValue(mainButtonBorderRadius) ?? 0,
  },
  secondaryButtonHoverBackgroundColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.7),
  },
  secondaryButtonHoverColorAndOpacity: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.7),
  },
  secondaryButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
});
